<template>
<div class="page_content_wrap">

	<div class="let_gets_to_know_you_wrap py-5">
		<div class="container">
			<div class="let_gets_to_know_you_box text-center">
                <h3 class="text-dark mb-3">Two Special Withdrawal Situations</h3>
                <p>Learn about early withdrawal and excess withdrawal.</p>
            </div>
            <div class="video-ifream text-center">
                <iframe src="//fast.wistia.com/embed/iframe/msl9mufiql?videoFoam=true&amp;playbar=false" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" oallowfullscreen="oallowfullscreen" msallowfullscreen="msallowfullscreen" width="1000"  height="620"></iframe>
            </div>
		</div>
    </div>

    <div class="knowledge_toggle-section pb-5">
        <div class="container">
            <div class="knowledge_inner-box px-5">
                <div class="knowledge_toggle_box text-dark ">
                    <p class=" font-size-sm">Let's test your knowledge</p>
                    <p>In which situation would surrender charges apply?</p>
                </div>
                <div class="accordion" id="accordion_one">
                    <div class="accordion_box bg-pastel-success mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_1" aria-expanded="false">
                                01. Mary purchases an annuity that allows her to withdraw 10% penalty free each year. She withdraws 7% of the accumulation value of her annuity in the 7th year of her annuity
                            </h5>
                        </div>
                        <div id="accordion_one_1" class="collapse p-4" data-parent="#accordion_one" style="">
                            <div class="accordion_body">Incorrect - Surrender charges only apply if your withdrawal exceeds 10% of the accumulation value in the contract year.</div>
                        </div>
                    </div>
                    <div class="accordion_box bg-pastel-success mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_2" aria-expanded="false">
                                02.Mary withdraws the entire Accumulation Value from her annuity in the 2nd contract year
                            </h5>
                        </div>
                        <div id="accordion_one_2" class="collapse p-4" data-parent="#accordion_one" style="">
                            <div class="accordion_body">Correct - Surrender Charges apply when a withdrawal exceeds the penalty free withdrawal amount in the contract year. Early surrenders may also have a market value adjustment applied.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="knowledge_toggle_box text-dark font-weight-bold">
                    <p><span class="text-success">Correct -</span> The Death Benefit equals at least the accumulation value, but state law may require it to be higher if the accumulation value doesn’t meet a certain minimum required by state law.</p>
                </div> -->
            </div>
        </div>
    </div>

    <!-- Tabs -->
     <section class="pb-5 information-section" id="tabs">
        <div class="container">
            <div class="information-inner px-5">
                <h6 class="section-title text-dark">Key Information</h6>
                <p class="text-dark">Below is key information related to the special situations reference in the video above.</p>
                <div class="row">
                    <div class="col-12">
                        <nav>
                            <div class="nav nav-tabs nav-fill mb-0" id="nav-tab" role="tablist">
                                <a class="text-left active h6" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">MNL ENDEAVOR 12</a>
                            </div>
                        </nav>
                        <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <table class="table table-striped text-dark">
                                    <tbody>
                                    <tr>
                                        <td>SURRENDER CHARGE SCHEDULE</td>
                                        <td>
                                        <p>Year 1: 10%</p>   
                                        <p>Year 2: 10%</p> 
                                        <p>Year 3: 10%</p> 
                                        <p>Year 4: 10%</p> 
                                        <p>Year 5: 10%</p> 
                                        <p>Year 6: 9%</p> 
                                        <p>Year 7: 8%</p>
                                        <p>Year 8: 7%</p>
                                        <p>Year 9: 6%</p>
                                        <p>Year 10: 5%</p>
                                        <p>Year 11: 4%</p>
                                        <p>Year 12: 2%</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>MARKET VALUE ADJUSTMENT APPLIES</td>
                                        <td>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>MARKET VALUE ADJUSTMENT FACTOR</td>
                                        <td>0.25% see “More Information” for full calculation</td>
                                    </tr>
                                    <tr>
                                        <td>MINIMUM SURRENDER VALUE</td>
                                        <td>87.5% of all premiums; less any partial surrenders (after any Market Value Adjustment or reduction for surrender charges) accumulated at 1.00%</td>
                                    </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
<!-- ./Tabs -->

    <div class="more_information_section pb-5">
        <div class="container">
            <div class="knowledge_inner-box px-5">
                <div class="accordion" id="accordion_one">
                    <div class="accordion_box mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#information" aria-expanded="false">More information</h5>
                        </div>
                        <div id="information" class="collapse p-4" data-parent="#information">
                            <div class="accordion_body text-dark">
                                <div class="accumulation pb-3">   
                                    <h6>What is the Surrender Value?</h6>
                                    <p class="font-size-sm">The Surrender Value is the amount that is available to you if you decide to terminate, or surrender, your annuity early for its cash value. The Surrender Value is equal to the Accumulation Value, subject to the Interest Adjustment/Market Value Adjustment (if any), less applicable Surrender Charges and state premium taxes where applicable.</p>
                                    <p class="font-size-sm">The Minimum Surrender Value will never be less than 87.5% of all premiums less any partial surrenders (after Interest Adjustment/Market Value Adjustment or reduction for Surrender Charges); accumulated at a percentage required by your state or otherwise directed by your Contract. See the information above for the percentage at which it is accumulated.</p>
                                </div>
                                <div class="accumulation pb-3">   
                                    <h6>What are Surrender Charges and when do they apply?</h6>
                                    <p class="font-size-sm">A Surrender Charge applies to any amount withdrawn from your annuity in excess of the penalty-free amount available to you at that time. This may result in a loss of premium. Surrender charges only apply during the Surrender Charge Period that applies to your annuity contract. Surrender charges allow Midland National to invest long-term, and in turn, generally credit higher yields. The surrender charges that apply for each contract year are based on the state where your contract is issued and are shown in the gray box.</p> 
                                </div>
                                <div class="accumulation pb-3">   
                                    <h6>What is the Interest Adjustment (also known as Market Value Adjustment)?</h6>
                                    <p class="font-size-sm">An Interest Adjustment is a calculation that is applied during the Surrender Charge Period to any amount withdrawn from your annuity in excess of the penalty-free amount available to you at that time. This adjustment may decrease or increase the Surrender Value depending on the change in interest rates during the period since you purchased this annuity. Lower interest rates at the time you purchased your annuity may result in less opportunity for a positive Interest Adjustment in future contract years. In certain rate scenarios at the time of issue, it may not be possible to experience a positive Interest Adjustment.</p> 
                                    <p class="font-size-sm">The Interest Adjustment may not apply in your state, see the gray box information above for more information.</p> 
                                    <p class="font-size-sm">The Interest Adjustment is calculated by taking the rate in effect at the time the contract was issued minus the rate offered for new contracts at the time of a partial or full surrender minus the adjustment factor shown in the gray box above. This amount is multiplied by the amount of time remaining in your contract.</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 

     <div class="disclaimer py-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="more-information text-dark">
                        <h5>Disclaimer</h5>
                        <p>The MNL Endeavor 12 is issued by Midland National® Life Insurance Company, West Des Moines, IA. This product, its features and riders may not be available in all states. Please request the product disclosure for additional details and limitations.</p>
                        <p class="mb-0">Fixed Index Annuities are not a direct investment in the stock market. They are long term insurance products with guarantees backed by the issuing company. They provide the potential for interest to be credited based in part on the performance of specific indices, without the risk of loss of premium due to market downturns or fluctuation. Although fixed index annuities guarantee no loss of premium due to market downturns, deductions from your accumulation value for additional optional benefit riders could under certain scenarios exceed interest credited to the accumulation value, which would result in a loss of premium. They may not be appropriate for all clients.</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 

    <div class="footer_bottom py-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="back-box d-inline-block pt-3">
                        <a class="text-dark h5 text-decoration-none" href="#"><i class="far fa-arrow-left mr-2"></i>Back</a>
                    </div>
                    <div class="understand-box d-inline-block float-right">
                        <p class="d-inline-block mr-3 text-dark">Please watch the video to continue</p>
                        <a href="#" class="btn btn-primary btn-lg">I Understand, Continue<i class="far fa-arrow-right ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {

}
</script>

<style>

</style>